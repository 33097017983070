import React from 'react';
import '../styles/mobile.scss';
import { FaFacebookF, FaInstagram, FaPhoneAlt, FaLinkedinIn, FaYoutube } from 'react-icons/fa';
import ParallaxRow from './ParallaxRow';

const jumboImg = require("../images/foto_jumbo_mobile.jpg")

class Mobile extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }


    render() {
        return (
            <div className="mobile" style={{
                //marginLeft: 30,
                //marginRight: 30
            }}>
                <div className="jumbo">
                    <img src={jumboImg} />
                    <div><h1>Denisa Nepevná</h1></div>
                </div>
                <p style={{ marginTop: 0, paddingTop: 20 }}>Tento web obsahuje praktické ukázky práce, které jsou dostupné jen ve verzi pro počítač. Pro kompletí náhled web otevřete na počítači.</p>
                <h2>O mně</h2>
                <p>Jmenuji se Denisa Nepevná a soustředím se na <strong>efektivní prodeje nemovitostí.</strong></p>
                <p>Má přidaná hodnota je poskytování kvalitního on-line marketingu a dodržování třech pravidel: <strong>Kvalita, osobní přístup a upřímná komunikace.</strong></p>
                <p>Pomáhám svým klientům se zprostředkováním celého procesu prodeje, pronájmu nebo investice do nemovitosti. Vždy dělám vše proto, aby strana, kterou zastupuji byla <strong>chráněna</strong> a nad míru <strong>spokojena</strong>. K tomu využívám nejmodernější marketingové nástroje a spolupracuji s těmi <strong>nejlepšími na trhu</strong>, a to ať už se jedná o advokáty, fotografy, kameramany, hypoteční poradce, nebo home stagery.</p>
                <p>Spolupracuji s nadnárodní značkou Keller Williams Czech Republic, kde působím jako <strong>realitní makléř</strong>, ale také se věnuji <strong>školení nových makléřů</strong>, a to především v oblastech obchodních dovedností, komunikace a práce s kontakty.</p>
                <ParallaxRow title="Hodnota #1" >
                    <h2>Osobní a zodpovědný přístup</h2>
                    <p>Vážím si každé spolupráce a důvěry, kterou ve mě klient vloží. Se svými klienty mám ve zvyku udržovat kontakt a informovat je o veškerém dění ohledně nemovitosti.</p>
                </ParallaxRow>
                <h2>
                    Profesionální fotografie nemovitosti
              <div className="line" />
                </h2>

                <p>Prodej nemovitosti bez profesionálních fotografií si zkrátka nedokážu představit. Jedná se o část inzerce, která je vidět jako první a právě proto musí zaujmout co nejvíc to jde. Proto spolupracuji s těmi <strong>nejlepšími fotografy v Praze</strong> a věřte, že kvalitní fotografie dělají divy. Ostatně posuďte sami.</p>

                <p>K detailní prezentaci používám i <strong>fotografie a videa z dronu</strong>. Tato technika je důležitá zejména u prodeje pozemků či nemovitostí s velkou parcelou.</p>

                <p style={{ fontWeight: 'bold' }}>Pro praktickou ukázku prosím navštivte web na počítači.</p>

                <ParallaxRow title="Hodnota #2" >
                    <h2>Nadnárodní přesah</h2>
                    <p>Keller Williams je jedna z největších realitních frančíz na světě. Proto máme skvělou možnost spolupracovat s kolegy z celého světa. Navzájem si tak sdílíme zkušenosti, znalosti a aktivně spolupracujeme. Plynulá komunikace v anglickém jazyce je pro mě samozřejmostí a stejně tak i veškerá smluvní dokumentace, kterou pro Vás máme připravenou také v anglickém jazyce.</p>
                </ParallaxRow>

                <h2>Home staging</h2>
                <p><strong>Jak klasický, tak virtuální</strong>. Výběr bude záležet na Vás, většinou se ale orientujeme podle toho, v jakém stavu nemovitost je.</p>
                <p>O co se vlastně jedná? O často podceňovaný krok při prodeji nemovitosti. A přitom to je úplně běžný <strong>marketingový nástroj</strong>, který se používá prostě všude. Ukažme si např. na fast food reklamu. Stačí se podívat na fotografii pokrmu - těžké odolat, že? Ale ačkoliv si objednáte úplně to stejné, co Vás přilákalo na fotografii, rozhodně to stejné na talíři mít nebudete. Ale nalákalo Vás to? Nalákalo. Pochutnali jste si? Pochutnali. Takže účel splněn. </p>
                <p>Cíl je vždy stejný, <strong>zvýšit prodejní cenu!</strong> A pokud to pro Vás váš makléř neudělá, jednoduše Vás připravuje o peníze a co je horší, i o váš čas. </p>

                <p style={{ fontWeight: 'bold' }}>Pro praktickou ukázku prosím navštivte web na počítači.</p>

                <ParallaxRow title="Hodnota #3" >
                    <h2>Obchodní komunikace</h2>
                    <p>Obchodních zkušeností není nikdy dost a já ty své sbírám nejen z praxe, ale i z mnohých školení, ať už v rámci naší společnosti, tak mimo ni. </p>
                </ParallaxRow>

                <h2>Videoprohlídka nemovitosti i jejího okolí</h2>
                <p>Videoprohlídka je nedílnou součástí <strong>moderního marketingu</strong>. Zaměřuji se jak na samotnou nemovitost, tak na celkové vyžití v dané lokalitě, protože každá lokalita má co nabídnout a je jedním z <strong>rozhodujících faktorů při prodeji</strong>. Navíc má klient možnost si sám sebe v nemovitosti živě představit. Je tedy velká chyba nevyužívat tento nástroj k efektivnímu prodeji. </p>
                <p>Podívejte se, jaké videoprohlídky nemovitostí standardně zpracovávám.
        </p>

                <p style={{ fontWeight: 'bold' }}>Pro praktickou ukázku prosím navštivte web na počítači.</p>

                <ParallaxRow title="Hodnota #4" >
                    <h2>Spolupracuji</h2>
                    <p>Považuji za velmi důležité <strong>spolupracovat</strong> s ostatními realitními makléři <strong>napříč trhem</strong>. Proto se u každého obchodu smluvně zavazuji k tomu, že pokud mi jiný makléř přivede zájemce o Vaši nemovitost, <strong>podělím se s ním o 30% ze své provize.</strong> Stejně tak to platí i pro Vás. Pokud se na Vás obrátí zájemce o koupi neveřejnou cestou (např. někdo z rodiny / ze známých), zaplatíte nám provizi sníženou o 30%.</p>
                </ParallaxRow>

                <h2>Virtuální prohlídka</h2>
                <p>Virtuální prohlídka je jeden z nástrojů prodeje nemoviotsti, o který by Vás neměl žádný makléř připravit. A už vůbec ne v dnešní době epidemie. </p>
                <p>Právě kvůli přetrvávajícímu nouzovému stavu a jiným omezením jsem o to vděčnější, že díky technologiím a našim šikovným kolegům ze světa fotoaparátů, kamer a matterportů Vám můžeme poskytovat <strong>stoprocentní servis</strong>. A tady máte ukázku toho, jak si můžete <strong>“projít” nemovitost i z pohodlí vašeho domova.</strong> </p>

                <p style={{ fontWeight: 'bold' }}>Pro praktickou ukázku prosím navštivte web na počítači.</p>

                <ParallaxRow title="NĚCO NAVÍC" >
                    <h2>Zpracování cenového odhadu nemovitosti</h2>
                    <p>Snažíme se nám svěřené nemovitosti prodat co nejrychleji a za co nejvyšší cenu. Cena ale samozřejmě musí odpovídat dané situaci na trhu, a proto pro Vás vždy rádi vypracujeme profesionální odhad ceny zdarma.</p>
                </ParallaxRow>

                <h2>Placené reklamy na sociálních sítích</h2>
                <p>Nemovitosti <strong>propaguji</strong> na sociálních sítích, čímž jsem schopná oslovit <strong>větší množství zájemců</strong>, než pouze z inzertních portálů. Počet lidí, kteří chodí na realitní portály je omezený. Navíc díky placené reklamě na sociálních sítích mohu <strong>zaměřit</strong> určitou skupinu potenciálních kupců, a to třeba i v zahraničí. </p>
                <p>Díky pochopení, kdo je ideální zákazník pro koupi Vaší nemovitosti a správně nastavenému cílení dokážu reklamu vaši nemovitosti dostat mezi daleko více <strong>potenciálních kupujících</strong>. Tak například mezi realitní investory - tedy mezi lidi, kteří mají peníze, chtějí koupit, ale nic je netlačí, a proto nechodí každý den na Sreality. </p>


                <ParallaxRow title="NĚCO NAVÍC" >
                    <h2>Konzultace zdarma</h2>
                    <p>Když můžu, tak pomůžu! A je už jen na Vás, zda konzultaci využijete k tomu, abyste se dozvěděli víc o mně, nebo abych Vám poradila, jak <strong>efektivně a bezpečně prodat Vaší nemovitost</strong>, pokud se rozhodnete prodávat na vlastní pěst.
</p>
                </ParallaxRow>

                <h2>Precizní prohlídky nemovitostí</h2>
                <p>Na prohlídky nemovitosti jsem vždy <strong>maximálně připravena</strong>. Zájemcům o nemovitost poskytuji kvalitně zpracované materiály ve formě brožury. Považuji za svou povinnost, aby byla strana kupujících o všem <strong>informována</strong> a aby se cítili komfortně a mohli se spolehnout na to, že celý proces proběhne bez komplikací. </p>
                <p>Pro zájemce vždy připravuji menší občerstvení, ale zároveň dávám důraz na <strong>hygienu</strong>. Zájemcům, kteří přijdou na prohlídku poskytuji návleky na boty, roušky a antibakteriální gely.</p>

                <p style={{ fontWeight: 'bold' }}>Pro praktickou ukázku prosím navštivte web na počítači.</p>
            </div>
        );
    }
}

export default Mobile;


