import React from 'react';
import '../styles/parallax.scss';
import { isMobile } from 'react-device-detect';

class ParallaxRow extends React.Component {

    render() {
        let style = {
            backgroundImage: `url(${require("../images/vaclavak_sm.jpg")})`
        }
        if(!isMobile) {
            style.height = this.props.height || 300
        }
        return (
            <div className="parallax" style={style}>
                <div className="content" style={{ paddingLeft: (isMobile? 20 : '10%'), paddingRight: (isMobile? 20 : '10%') }}>
                    <div className="title"><p style={{ fontSize: (isMobile? '3em' : '4.2em') }}>{this.props.title}</p></div>
                    <div style={{ marginTop: (isMobile? 75 : 0) }}>{this.props.children}</div>
                </div>
            </div>

        );
    }
}

export default ParallaxRow;
