import React from 'react';
import '../styles/contact.scss';
import emailjs from 'emailjs-com';

const defaultValues = {
    jmeno: '',
    email: '',
    zprava: ''
}

export default class ContactForm extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            values: defaultValues
        }
    }

    componentDidMount() {
        emailjs.init('user_WPAW1mLVbvLovXLJKcJL3')
    }

    onChange(e, type) {
        let { values } = this.state;
        values[type] = e.target.value
        this.setState({ values });
    }

    submit() {
        console.log(this.state.values)
        emailjs.send('service_tdk3l1k', 'template_dfgrinh', this.state.values)
            .then(function (response) {
                alert('Děkuji za vaši zprávu, co nevidět se vám ozvu.')
                this.setState({values: defaultValues})
            }, function (error) {
                console.error(error)
                alert('Něco se pokazilo, na opravě pracujeme.')
            });
    }

    /*handleSubmit(event) {
        console.log('handle', this.state.values);
        event.preventDefault();
    }*/

    render() {
        console.log(this.state.values)
        return (
            <div className="contact" >
                <div>
                    <label>Jméno</label>
                    <input type="text" value={this.state.jmeno} onChange={(e) => this.onChange(e, 'jmeno')} />
                </div>
                <div>
                    <label>Email</label>
                    <input type="text" value={this.state.email} onChange={(e) => this.onChange(e, 'email')} />
                </div>
                <div>
                    <label>Váše zpráva</label>
                    <textarea value={this.state.zprava} onChange={(e) => this.onChange(e, 'zprava')}></textarea>
                </div>
                <div>
                    <button onClick={this.submit.bind(this)}>Odeslat</button>

                </div>
            </div>
        );
    }
}
