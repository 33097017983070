import React from 'react';
import '../styles/bottomRow.scss';
import ContactForm from './ContactForm';

function BottomRow() {
  return (
    <footer id="kontakt">
      {false && <div className="bottom">
        <img className="background" src={require("../images/modern-building-against-sky-323780.jpg")} />
        {/*<img className="overlay" src={require("../images/overlay_bottom.png")} />*/}
        <h2>#1 REALTOR IN CZECH (maybe)</h2>
      </div>}
      <div className="footer" style={{ backgroundImage: `url(${require("../images/footer_img.jpg")})` }} >
        {false && <div>
          <img className="bg" src={require("../images/footer_img.jpg")} />
        </div>}
        <div className="block left">
          <div className="wrapper">
            <img className="logo" src={require("../images/KellerWilliams_CzechRepublic_Logo_CMYK.png")} />
            <p>+420 737 001 032</p>
            <p>denisa.nepevna@kwcz.cz</p>
            <p>IČO: 08531293</p>
            <p className="small">Kaprova 52/6, Praha 1</p>
            <p className="smaller">Fyzická osoba podnikající dle živnostenského zákona
Datum vzniku: 18. 9. 2019</p>
          </div>
        </div>
        <div className="divider" />
        <div className="block right">
          <div>
            <ContactForm />
          </div>
        </div>
      </div>
    </footer>
  );
}

export default BottomRow;
