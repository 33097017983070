import React from 'react';
import '../styles/row.scss';
import brozura from './brozura.pdf';
import '../styles/carousel.css';
import { Carousel } from 'react-responsive-carousel';

import PdfView from './PdfView';
import CompareRow from './CompareRow';

const images = [
    {
        original: require("./gallery/1.jpg"),
        thumbnail: require("./gallery/1_thumb.jpg"),
        width: '500px'
    },
    {
        original: require("./gallery/2.jpg"),
        thumbnail: require("./gallery/2_thumb.jpg"),
        width: '500px'
    }
]

class Row extends React.Component {


    _renderRow(align) {
        switch (align) {
            default:
                case 'center':
                return (<div className="row center">
                    <div className="">
                        {this.props.children}
                    </div>
                </div>)
            case 'left':
                return (<div className="row">
                    <div className="small">
                        <img className="left" src={require("../images/" + this.props.imgString)} />
                    </div>
                    <div className="big right">
                        <h2>
                            {this.props.title}
                            <div className="line" />
                        </h2>
                        {this.props.children}
                    </div>
                </div>)

            case 'right':
                return (<div className="row">
                    <div className="big left">
                        <h2 id={this.props.id}>
                            {this.props.title}
                            <div className="line" />
                        </h2>
                        {this.props.children}
                    </div>
                    <div className="small">
                        <img className="right" src={require("../images/" + this.props.imgString)} style={(this.props.noShadow && {boxShadow: "0 0 0"})} />
                    </div>
                </div>)

case 'youtube':
    return (<div className="row">
        <div className="big left">
            <h2>
                {this.props.title}
                <div className="line" />
            </h2>
            {this.props.children}
        </div>
        <div className="small">
        <iframe width="560" height="315" src="https://www.youtube.com/embed/lF_YCWQPTpU" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        </div>
    </div>)

            case 'matterport':
                return (<div className="row">
                    <div className="big left" style={{ marginLeft: -50 }}>
                        <iframe width="800" height="480" src="https://my.matterport.com/show/?m=ittcAnWwhsz" frameBorder="0" allowFullScreen allow="xr-spatial-tracking"></iframe>
                    </div>
                    <div className="small matterport">
                        <h2>
                            {this.props.title}
                            <div className="line" />
                        </h2>
                        {this.props.children}
                    </div>
                </div>)

            case 'pdf':
                return (<div className="row">
                    <div className="small left">
                        <h2>
                            {this.props.title}
                            <div className="line" />
                        </h2>
                        {this.props.children}
                    </div>
                    <div className="big right" style={{ paddingRight: 0 }}>
                        <PdfView file={brozura} />
                    </div>
                </div>)

            case 'compare':
                return (<CompareRow id={this.props.id} title={this.props.title} children={this.props.children} type={this.props.type} />)

            case 'gallery':
                return <div className="row" style={{ marginTop: 50, display: 'flex', justifyContent: 'flex-start' }}><div style={{ width: '40%', marginLeft: '10%', marginRight: '10%' }}>
                    <Carousel>
                        <div>
                            <img src={require("./gallery/1.jpg")} />
                        </div>
                        <div>
                            <img src={require("./gallery/2.jpg")} />
                        </div>
                        <div>
                            <img src={require("./gallery/3.jpg")} />
                        </div>
                        <div>
                            <img src={require("./gallery/4.jpg")} />
                        </div>
                        <div>
                            <img src={require("./gallery/5.jpg")} />
                        </div>
                        <div>
                            <img src={require("./gallery/6.jpg")} />
                        </div>
                        <div>
                            <img src={require("./gallery/7.jpg")} />
                        </div>
                        <div>
                            <img src={require("./gallery/8.jpg")} />
                        </div>
                        <div>
                            <img src={require("./gallery/9.jpg")} />
                        </div>
                        <div>
                            <img src={require("./gallery/10.jpg")} />
                        </div>
                        <div>
                            <img src={require("./gallery/11.jpg")} />
                        </div>
                        <div>
                            <img src={require("./gallery/12.jpg")} />
                        </div>
                        <div>
                            <img src={require("./gallery/13.jpg")} />
                        </div>
                        <div>
                            <img src={require("./gallery/14.jpg")} />
                        </div>
                        <div>
                            <img src={require("./gallery/15.jpg")} />
                        </div>
                    </Carousel>
                </div>
                    <div style={{ width: '30%', float: 'right', zIndex: 100, marginRight: '10%', justifyContent: 'flex-start' }}>
                        <div>
                        {this.props.children}
                        </div>
                    </div>
                </div>
        }
    }

    render() {
        return (
            this._renderRow(this.props.align)
        );
    }
}

export default Row;
