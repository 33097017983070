import React from 'react';
import '../styles/backgroundOverlay.scss';

export default function BackgroundOverlay() {
  return (
    <div className="bgContainer">
      <div className="bgOverlay">
      <img src={require("../images/background.png")} />
      </div>
      <div className="bgBottom" />
    </div>
  );
}
