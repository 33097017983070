import React from 'react';
import ReactCompareImage from 'react-compare-image';

class CompareRow extends React.Component {

    render() {
        try{
        return (
            <div className="compare">
            <h2 id={this.props.id}>
                {this.props.title}
                <div className="line" />
            </h2>
                <div style={{ marginLeft: '2%', marginRight: '2%' }}>

                <div className="compareWrapper">
                        <ReactCompareImage
                            aspectRatio='wider'
                            leftImage={require(`./compare/${this.props.type}/3/left.jpg`)}
                            rightImage={require(`./compare/${this.props.type}/3/right.jpg`)} />
                    </div>
                    <div className="compareWrapper">
                        <ReactCompareImage
                            aspectRatio='wider'
                            leftImage={require(`./compare/${this.props.type}/2/left.jpg`)}
                            rightImage={require(`./compare/${this.props.type}/2/right.jpg`)} />
                    </div>
                    <div className="compareWrapper">
                        <ReactCompareImage
                            aspectRatio='wider'
                            leftImage={require(`./compare/${this.props.type}/4/left.jpg`)}
                            rightImage={require(`./compare/${this.props.type}/4/right.jpg`)} />
                    </div>
                </div>
            </div>
        );} catch (err) { console.error(err) }
    }
}

export default CompareRow;
