import React from 'react';
import '../styles/jumbo.scss';
import { Link, animateScroll as scroll } from "react-scroll";

import { IconContext } from "react-icons";
import { FaFacebookF, FaInstagram } from 'react-icons/fa';

const jumboImg = require("../images/foto_jumbo.jpg")
const jumboOverlay = require("../images/overlay_jumbo.png")

function Jumbo() {
  return (
    <div>
    <div className="jumbo">
      <img className="background" src={jumboImg} />
      {/*<img className="overlay" src={require("../images/overlay_2.png")} />*/}
      <img className="secondOverlay" src={jumboOverlay} />
      <div className="text">
        <h1>DENISA<br/>NEPEVNÁ</h1>
        <p>“Nejraději pracuji s lidmi, kteří věří mým schopnostem a vidí v mé práci přidanou hodnotu, protože vím, že ji mohu nabídnout.”</p>
        {false && <p className="icon">
        <p className="icon">
          <FaFacebookF />
          <FaInstagram />
        </p>
        </p>}
        <Link to="kontakt" smooth={true} duration={500} offset={-200} style={{ cursor: 'pointer' }}>Kontakt</Link>
      </div>
    </div>
    {/*<div className="transition-element">
      <img className="triangle" src={require("../images/triangle.png")} />
    </div>*/}
    </div>
  );
}

export default Jumbo;
