import React from 'react';
import '../styles/menu.scss';
import { FaFacebookF, FaInstagram, FaPhoneAlt, FaLinkedinIn, FaYoutube } from 'react-icons/fa';
import { Link, animateScroll as scroll } from "react-scroll";

class Menu extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            scrolled: 0
        };

        this._handleScroll = this._handleScroll.bind(this);
    }

    componentDidMount() {
        window.onscroll = this._handleScroll
    }

    componentWillUnmount() {
        window.onscroll = null;
    }

    _handleScroll() {
        console.log('scrolled');
        this.setState({ scrolled: window.pageYOffset })
    }

    render() {
        let { scrolled } = this.state;
        if (scrolled > 900) scrolled = 900;
        let style = {
            background: "rgba(0,0,0," + scrolled / 1200 + ")"
        }
        let telefonek = 255 * (scrolled/1000);
        let telefonekColor = 'rgb('+telefonek+','+telefonek+','+telefonek+')';
        return (
            <div className="menu" style={style} >
                <div className="left">
                    <img className="logo" src={require("../images/logo.png")} />
                </div>
                <div className="right">
                <Link to="fotografie" smooth={true} duration={500} offset={-200} className="item">Fotografie</Link>
                <Link to="homestaging" smooth={true} duration={500} offset={-200} className="item">Homestaging</Link>
                <Link to="propagace" smooth={true} duration={500} offset={-200} className="item">Propagace</Link>
                <Link to="kontakt" smooth={true} duration={500} offset={-200} className="item">Kontakt</Link>
                </div>
                <div className="kontakt">
                    <a href="https://www.facebook.com/Denisanepevnareality" target="_blank"><span className="socka"><FaFacebookF /></span></a>
                    <a href="https://www.instagram.com/denisanepevna_reality/" target="_blank"><span className="socka"><FaInstagram /></span></a>
                    <a href="https://www.youtube.com/channel/UC43iZIwE2_S4zfDZMIQQ-_w" target="_blank"><span className="socka"><FaYoutube /></span></a>
                    <a href="https://www.linkedin.com/in/denisa-nepevn%C3%A1" target="_blank"><span className="socka"><FaLinkedinIn /></span></a>
                    <span><FaPhoneAlt style={{ marginTop: 5, marginLeft: 30, color: ((scrolled > 500) ? telefonekColor : 'black')}} /></span>
                    <span style={{ color: ((scrolled > 500) ? telefonekColor : 'black') }}>+420 737 001 032</span>

                </div>
            </div>
        );
    }
}

export default Menu;
