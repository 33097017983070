import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { IconContext } from "react-icons";
import { FaAngleDoubleLeft, FaAngleDoubleRight } from 'react-icons/fa';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function PdfView(props) {
    const [numPages, setNumPages] = useState(null);
    let [pageNumber, setPageNumber] = useState(1);

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    return (
        <div>
            <Document
                file={props.file}
                onLoadSuccess={onDocumentLoadSuccess}
            >
                <Page pageNumber={pageNumber} />
            </Document>
            <div style={{ flexDirection: 'row' }}>
                <IconContext.Provider value={{ size: '2em', style: { padding: 10 } }}>
                    <button style={
                        { border: 0, backgroundColor: 'rgba(0,0,0,0)', cursor: 'pointer' }
                    } onClick={() => {
                        pageNumber -= 1;
                        if(pageNumber < 1) pageNumber = numPages;
                        setPageNumber(pageNumber)
                    }}  >
                        <FaAngleDoubleLeft />
                    </button>

                    <p>Stránka {pageNumber} z {numPages}</p>

                    <button style={
                        { border: 0, backgroundColor: 'rgba(0,0,0,0)', cursor: 'pointer' }
                    } onClick={() => {
                        pageNumber += 1;
                        if(pageNumber > numPages) pageNumber = 1;
                        setPageNumber(pageNumber)
                    }}  >
                        <FaAngleDoubleRight />
                    </button>
                </IconContext.Provider>
            </div>
        </div>
    );
}